<template>
    <div>
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col
                        cols="12"
                        md="12"
                    >
                        <b-card no-body>
                            <b-card-header>
                                <h4 class="card-title">{{ i18nT(`Employee`) }}</h4>
                                <b-dropdown
                                    variant="link"
                                    toggle-class="p-0"
                                    no-caret
                                    right
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="16"
                                            class="align-middle text-body"
                                        />
                                    </template>
                                    <!--                  <b-dropdown-item @click="onCopy()">-->
                                    <!--                    <span class="align-middle ml-50">{{i18nT(`Copy`)}}</span>-->
                                    <!--                  </b-dropdown-item>-->
                                    <b-dropdown-item @click="onSave()">
                                        <span class="align-middle ml-50">{{i18nT(`Save`)}}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="sendInvite()"
                                                     v-if="profileData.PublicationStatus !== 'ACTIVE'"
                                    >
                                        <span class="align-middle ml-50" v-if="profileData.Invited === 1">{{i18nT(`Resend Invitation`)}}</span>
                                        <span class="align-middle ml-50" v-else>{{i18nT(`Send Invitation`)}}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item
                                        v-if="hasRouteId"
                                        @click="onDelete()"
                                    >
                    <span
                        class="align-middle ml-50"
                        style="color: red"
                    >{{i18nT(`Delete`)}}</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-card-header>
                            <b-card-body>
                                <b-row class="border-bottom mb-1">
                                    <b-col cols="3">
                                        <h5 class="mt-2">
                                            {{ i18nT(`Profile image`) }}
                                        </h5>
                                    </b-col>
                                    <b-col md="9">
                                        <avatar-control
                                            :profileData="profileData"
                                            :parent-id="profileData.Id"
                                            @setNewAvatar="onSetNewAvatar"

                                        />
                                    </b-col>
                                </b-row>
                                <b-row class="border-bottom">
                                    <!-- Field: Username -->
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5 class="mt-2">
                                            {{i18nT(`Employee details`)}}
                                        </h5>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <b-row>
                                            <div class="demo-inline-spacing pl-1 mb-2">
                                                <b-form-radio
                                                    v-model="profileData.Gender"
                                                    value="female"
                                                >
                                                    {{i18nT(`Female`)}}
                                                </b-form-radio>
                                                <b-form-radio
                                                    v-model="profileData.Gender"
                                                    value="male"
                                                >
                                                    {{i18nT(`Male`)}}
                                                </b-form-radio>
                                            </div>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`First name`)"
                                                    label-for="firstName"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`First name`)"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            v-model="profileData.FirstName"
                                                            :state="errors.length > 0 ? false:null"
                                                            id="First name"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Last name`)"
                                                    label-for="lastName"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Last name`)"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            id="Last name"
                                                            v-model="profileData.LastName"
                                                            :state="errors.length > 0 ? false:null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                                v-if="iCan('personnal_data', 'read')"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`E-Mail`)"
                                                    label-for="title"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`E-Mail`)"
                                                        rules="required|email"
                                                    >
                                                        <b-form-input
                                                            v-model="profileData.Email"
                                                            :state="errors.length > 0 ? false:null"
                                                            :disabled="!iCan('contact_data', 'write')"
                                                            id="email"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                                v-if="iCan('personnal_data', 'read')"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Phone`)"
                                                    label-for="title"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Phone`)"
                                                        :rules="{ validPhoneNumber: mazphoneObj }"
                                                    >
                                                        <MazPhoneNumberInput
                                                            :key="phoneReady"
                                                            id="Phone"
                                                            default-country-code="FR"
                                                            v-model="profileData.Phone"
                                                            @update="updatePhoneObj"
                                                            :translations="{
                                      countrySelectorLabel: i18nT(`Country code`),
                                      countrySelectorError: i18nT(`Select a country`),
                                      phoneNumberLabel: i18nT(`Phone number`),
                                      example: i18nT(`Example:`),
                                    }"
                                                            :disabled="!iCan('contact_data', 'write')"
                                                        />
                                                        <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>


                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="4"
                                            >
                                                <validation-provider
                                                    #default="{ errors }"
                                                    :name="i18nT(`First name`)"
                                                    rules="date_format"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Date of birth`)"
                                                    >
                                                        <date-picker
                                                            v-model="profileData.BirthDate"
                                                            reset-button
                                                            :manual-input="true"
                                                            :state="errors.length > 0 ? false : null"
                                                        />
                                                        <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="4"
                                                v-if="iCan('personnal_data', 'read')"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Nationality`)"
                                                    label-for="nationality"
                                                >
                                                    <b-form-input
                                                        id="Nationality"
                                                        v-model="profileData.Nationality"
                                                        :disabled="!iCan('personnal_data', 'write')"
                                                    />
                                                </b-form-group>
                                            </b-col>

                                            <b-col
                                                cols="12"
                                                md="4"
                                                v-if="iCan('personnal_data', 'read')"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Social security`)"
                                                    label-for="SocialSecurity"
                                                >
                                                    <b-form-input
                                                        id="SocialSecurity"
                                                        v-model="profileData.SocialSecurity"
                                                        :disabled="!iCan('personnal_data', 'write')"
                                                    />
                                                </b-form-group>
                                            </b-col>


                                        </b-row>


                                        <b-row  v-if="!profileData.Id || profileData.Invited !== 1">
                                            <b-col
                                                cols="12"
                                                md="12"
                                            >
                                                <b-form-checkbox
                                                    name="check-button"
                                                    switch
                                                    :checked="sendInitialInvite"
                                                    v-model="sendInitialInvite"
                                                >
                                                    {{i18nT(`Send an invitation to join the software and confirm the account.`)}}
                                                </b-form-checkbox>
                                            </b-col>
                                        </b-row>
                                        <b-alert
                                            class="mt-1"
                                            variant="primary"
                                            show
                                            v-if="!profileData.Id"
                                        >
                                            <div class="alert-body">
                        <span>
                          <b-badge variant="primary">
                            {{i18nT(`Info`)}}
                          </b-badge>
                          {{i18nT(`You can notify and invite the person to join Skileo and create his account. Once he creates his profile, he will be able to access, complete and rectify any personal information.`)}}
                        </span>
                                            </div>
                                        </b-alert>
                                    </b-col>
                                </b-row>

                                <!-- PERSONAL ADDRESS -->
                                <b-row class="border-bottom"
                                       v-if="iCan('personnal_data', 'read')"
                                >
                                    <!-- Field: Username -->
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5 class="mt-2">
                                            {{i18nT(`Personal address`)}}
                                        </h5>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                        class="pt-2"
                                    >
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="12"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Address`)"
                                                    label-for="address"
                                                >
                                                    <b-form-input
                                                        :disabled="!iCan('contact_data', 'write')"
                                                        v-model="profileData.Address1" />
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="12"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Address 2`)"
                                                    label-for="address2"
                                                >
                                                    <b-form-input
                                                        :disabled="!iCan('contact_data', 'write')"
                                                        v-model="profileData.Address2" />
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Zip code`)"
                                                    label-for="zipCode"
                                                >
                                                    <b-form-input
                                                        :disabled="!iCan('contact_data', 'write')"
                                                        v-model="profileData.Zip" />
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`State`)"
                                                    label-for="state"
                                                >
                                                    <b-form-input
                                                        :disabled="!iCan('contact_data', 'write')"
                                                        v-model="profileData.State" />
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`City`)"
                                                    label-for="city"
                                                >
                                                    <b-form-input
                                                        :disabled="!iCan('contact_data', 'write')"
                                                        v-model="profileData.City" />
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Country`)"
                                                    label-for="country"
                                                >
                                                    <v-select
                                                        :disabled="!iCan('contact_data', 'write')"
                                                        id="country"
                                                        :options="countries"
                                                        v-model="profileData.Country"
                                                        label="text"
                                                        :reduce="(e) => e.value"
                                                        :clearable="false"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>

                                <!-- CONTACT INFORMATION -->
                                <b-row class="border-bottom">
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5 class="mt-2">
                                            {{i18nT(`Contract information`)}}
                                        </h5>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                        class="pt-2"
                                    >
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="12"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Job title`)"
                                                    label-for="jobTitle"
                                                >
                                                    <vue-bootstrap-typeahead v-model="profileData.Position"
                                                            :data="jobSuggestions" :serializer="returnText" />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="4"
                                                md="4"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Organization job level`)"
                                                    label-for="organizationJobLevel"
                                                >
                                                    <v-select
                                                        :clearable="false"
                                                        :options="jobLevels"
                                                        v-model="contractData.OnOrgJobLevel"
                                                        label="text"
                                                        :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="4"
                                                md="4"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Contract type`)"
                                                    label-for="contactType"
                                                >
                                                    <v-select
                                                        :clearable="false"
                                                        :options="contractTypes"
                                                        v-model="contractData.OnContractType"
                                                        label="text"
                                                        :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="4"
                                                md="4"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Employment type`)"
                                                    label-for="employmentType"
                                                >
                                                   
                                                    <v-select 
                                                        :options="employmentTypes"
                                                        v-model="contractData.FullHalfTime"
                                                        label="text"
                                                        :clearable="false"
                                                        :reduce="(e) => e.value"
                                                    />
                                                </b-form-group>



                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="12"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Department`)"
                                                    label-for="department"
                                                >
                                                    <v-select
                                                        :options="departments"
                                                        v-model="contractData.OnDepartment"
                                                        label="text"
                                                        :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                                        :clearable="false"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col cols="4">
                                                <b-form-group
                                                    :label="i18nT(`Start date`)"
                                                    label-for="Start date"
                                                >
                                                    <date-picker
                                                        id="Start date"
                                                        reset-button
                                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                        locale="en"
                                                        v-model="contractData.OnStartingFrom"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="4">
                                                <b-form-group
                                                    :label="i18nT(`Evaluation period end`)"
                                                    label-for="evaluationPeriodEnd"
                                                >
                                                    <date-picker
                                                        :min="contractData.OnStartingFrom"
                                                        id="evaluationPeriodEnd"
                                                        reset-button
                                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                        locale="en"
                                                        v-model="contractData.OnEvaluationPeriod"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="4">
                                                <b-form-group
                                                    :label="i18nT(`End date`)"
                                                    label-for="endDate"
                                                >
                                                    <date-picker
                                                        :min="contractData.OnStartingFrom"
                                                        id="endDate"
                                                        reset-button
                                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                        locale="en"
                                                        v-model="contractData.OnEnding"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>

                                <!-- WORK SCHEDULE -->
                                <b-row class="border-bottom">
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5 class="mt-2">
                                            {{i18nT(`Work schedule`)}}
                                        </h5>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                        class="pt-2 mb-1"
                                    >
                                        <b-row>
                                            <b-col
                                                cols="6"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Work hours`)"
                                                    label-for="Work hours"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Work hours`)"
                                                        rules="float"
                                                    >
                                                        <b-form-input id="Work hours" v-model="contractData.WorkHours" />
                                                        <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="6"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Type`)"
                                                    label-for="type"
                                                >
                                                    <v-select 
                                                        :options="workOptions"
                                                        v-model="contractData.WorkType"
                                                        label="text"
                                                        :clearable="false"
                                                        :reduce="e => e.value"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <p>{{i18nT(`Setting a custom work week helps to calculate the accurate amount of days used for time off requests.`)}}</p>
                                                <b-form-checkbox-group
                                                    id="checkbox-group-2"
                                                    v-model="contractData.WorkWeek"
                                                    name="flavour-2"
                                                    class="demo-inline-spacing"
                                                >
                                                    <b-form-checkbox
                                                        value="monday"
                                                        class="work-check"
                                                    >
                                                        {{i18nT(`Monday`)}}
                                                    </b-form-checkbox>
                                                    <b-form-checkbox
                                                        value="tuesday"
                                                        class="work-check"
                                                    >
                                                        {{i18nT(`Tuesday`)}}
                                                    </b-form-checkbox>
                                                    <b-form-checkbox
                                                        value="wednesday"
                                                        class="work-check"
                                                    >
                                                        {{i18nT(`Wednesday`)}}
                                                    </b-form-checkbox>
                                                    <b-form-checkbox
                                                        value="thursday"
                                                        class="work-check"
                                                    >
                                                        {{i18nT(`Thursday`)}}
                                                    </b-form-checkbox>
                                                    <b-form-checkbox
                                                        value="friday"
                                                        class="work-check"
                                                    >
                                                        {{i18nT(`Friday`)}}
                                                    </b-form-checkbox>
                                                    <b-form-checkbox
                                                        value="saturday"
                                                        class="work-check"
                                                    >
                                                        {{i18nT(`Saturday`)}}
                                                    </b-form-checkbox>
                                                    <b-form-checkbox
                                                        value="sunday"
                                                        class="work-check"
                                                    >
                                                        {{i18nT(`Sunday`)}}
                                                    </b-form-checkbox>
                                                </b-form-checkbox-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <!-- TIME OFF -->
                                <b-row class="pt-2 pb-2 border-bottom" v-if="leaveDays.length > 0">
                                    <b-col cols="12" md="3" >
                                        <h5>
                                            {{i18nT(`Time off`)}}
                                        </h5>
                                    </b-col>
                                    <b-col cols="12" md="9">
                                        <b-list-group>
                                            <b-list-group-item v-for="leaveDay in leaveDays" :key="'leaveDay-'+leaveDay.Id">
                                                <div class="d-flex justify-content-between font-small-3">
                                                    <div>
                                                        <span class="mr-2">{{leaveDay.Year}}</span>
                                                    </div>

                                                    <div v-if="leaveDay.CanTransferDays">
                                                        <span class="text-secondary">{{i18nT(`Transferred`)}}:</span>
                                                        <strong class="min-w-2rem">{{leaveDay.PrevYearDays}}</strong>
                                                    </div>

                                                    <div v-if="leaveDay.CompanyDays">
                                                        <span class="text-primary">{{i18nT(`Default`)}}:</span>
                                                        <strong class="min-w-2rem">{{leaveDay.CompanyDays}}</strong>
                                                    </div>

                                                    <div>
                                                        <span class="text-success">{{i18nT(`Additional`)}}:</span>
                                                        <strong class="min-w-2rem">{{leaveDay.AdditionalDays}}</strong>
                                                    </div>

                                                    <div>
                                                        <span class="">{{i18nT(`Total`)}}:</span>
                                                        <strong class="min-w-2rem">{{leaveDay.TotalDays}}</strong>
                                                    </div>

                                                    <div>
                                                        <span class="text-danger">{{i18nT(`Days taken`)}}:</span>
                                                        <strong class="min-w-2rem">{{leaveDay.TakenDays}}</strong>
                                                    </div>

                                                    <div>
                                                        <span class="text-warning">{{i18nT(`Remaining`)}}:</span>
                                                        <strong class="min-w-2rem">{{leaveDay.AvailableDays}}</strong>
                                                    </div>


                                                    <div class="d-flex align-items-center">
                                                        <feather-icon
                                                            v-if="leaveDay.Year == currentYear"
                                                            icon="Edit2Icon"
                                                            size="17"
                                                            class="text-primary cursor-pointer d-sm-block d-none mr-1"
                                                            @click="onEditLeave(leaveDay)"
                                                        />
                                                        <feather-icon
                                                            v-else
                                                            icon="Edit2Icon"
                                                            size="17"
                                                            class="d-sm-block d-none mr-1 text-muted"
                                                            v-b-tooltip.hover="i18nT(`You can only edit the current year`)"
                                                        />
                                                    </div>
                                                </div>
                                            </b-list-group-item>
                                        </b-list-group>
                                    </b-col>
                                </b-row>

                                <!-- SALARY -->
                                <b-row class="pt-2 pb-2 border-bottom"
                                       v-if="iCan('financial_data', 'read')"
                                >
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5>
                                            {{i18nT(`Salary`)}}
                                        </h5>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <h6 class="mb-1">{{i18nT(`Current salary`)}}</h6>
                                        <b-row>

                                            <b-col
                                                cols="4"
                                                md="4"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Net Salary`)"
                                                    label-for="salaryNet"
                                                >
                                                    <b-form-input
                                                        :disabled="!iCan('financial_data', 'write')"
                                                        v-model="contractData.OnSalaryNet" />
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="4"
                                                md="4"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Gross Salary`)"
                                                    label-for="salaryGross"
                                                >
                                                    <b-form-input
                                                        :disabled="!iCan('financial_data', 'write')"
                                                        v-model="contractData.OnSalaryGross" />
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="4"
                                                md="4"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Type`)"
                                                    label-for="salaryNet"
                                                >
                                                    <v-select
                                                        :disabled="!iCan('financial_data', 'write')"
                                                        :options="scheduleOptions"
                                                        v-model="contractData.OnSalaryType"
                                                        label="text"
                                                        :reduce="e => e.value"
                                                        :clearable="false"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <hr>
                                        <h6>{{i18nT(`Salary history`)}}</h6>
                                        <p>{{i18nT(`You can keep record of the salary evolution.`)}}</p>
                                        <div
                                            v-for="(item, index) in salary"
                                            :key="index"
                                            class="border-bottom pb-1"
                                        >
                                            <b-row>
                                                <b-col
                                                    cols="4"
                                                    md="4"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Previous net salary`)"
                                                        label-for="salaryNet"
                                                    >
                                                        <b-form-input
                                                            :disabled="!iCan('financial_data', 'write')"
                                                            v-model="item.Net" />
                                                    </b-form-group>
                                                </b-col>
                                                <b-col
                                                    cols="4"
                                                    md="4"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Previous gross salary`)"
                                                        label-for="salaryGross"
                                                    >
                                                        <b-form-input
                                                            :disabled="!iCan('financial_data', 'write')"
                                                            v-model="item.Gross" />
                                                    </b-form-group>
                                                </b-col>
                                                <b-col
                                                    cols="4"
                                                    md="4"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Type`)"
                                                        label-for="salaryNet"
                                                    >
                                                        <v-select
                                                            :disabled="!iCan('financial_data', 'write')"
                                                            :options="scheduleOptions"
                                                            v-model="item.Type"
                                                            label="text"
                                                            :reduce="(e) => e.value"
                                                            :clearable="false"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="6">
                                                    <b-form-group
                                                        :label="i18nT(`Start date`)"
                                                        label-for="startDate"
                                                    >
                                                        <date-picker
                                                            :disabled="!iCan('financial_data', 'write')"
                                                            reset-button
                                                            v-model="item.StartDate"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="6">
                                                    <b-form-group
                                                        :label="i18nT(`End date`)"
                                                        label-for="endDate"
                                                    >
                                                        <date-picker
                                                            :disabled="!iCan('financial_data', 'write')"
                                                            reset-button
                                                            v-model="item.EndDate"
                                                            :min="item.StartDate"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <div class="text-right" v-if="iCan('financial_data', 'write')">
                                                <b-button
                                                    variant="outline-danger"
                                                    @click="removeSalaryHistory(index)"
                                                >
                                                    <feather-icon icon="MinusCircleIcon" />
                                                    {{i18nT(`Remove`)}}
                                                </b-button>
                                            </div>
                                        </div>

                                        <div class="pt-1" v-if="iCan('financial_data', 'write')">
                                            <b-button
                                                variant="outline-primary"
                                                @click="addSalaryHistory"
                                            >
                                                <feather-icon icon="PlusCircleIcon" />
                                                {{i18nT(`Add more`)}}
                                            </b-button>
                                        </div>
                                    </b-col>
                                </b-row>
                                <!-- EQUITY AND BENEFITS -->
                                <b-row class="border-bottom"
                                       v-if="iCan('financial_data', 'read')"
                                >
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5 class="mt-2">
                                            {{i18nT(`Equity and benefits`)}}
                                        </h5>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                        class="pt-2"
                                    >
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="12"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Equity`)"
                                                    label-for="equityEnd"
                                                >
                                                    <b-form-input
                                                        :placeholder="i18nT(`Equity`)"
                                                        v-model="contractData.OnEquityEnd"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="12"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Benefits information`)"
                                                    label-for="benefitsInformation"
                                                >
                                                    <b-form-textarea
                                                        :placeholder="i18nT(`Brief Description`)"
                                                        v-model="contractData.OnBenefitsInfo"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>

                                <!-- ADDITIONAL INFO -->
                                <b-row class="border-bottom">
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5 class="mt-2">
                                            {{i18nT(`Additional Information`)}}
                                        </h5>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                        class="pt-2"
                                    >
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="12"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Teams`)"
                                                    label-for="employeeGroups"
                                                >
                                                    <v-select
                                                        :options="employeeGroupFilters"
                                                        v-model="employeeGroups"
                                                        label="text"
                                                        multiple
                                                        :clearable="false"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="12"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Information`)"
                                                    label-for="information"
                                                >
                                                    <b-form-textarea
                                                        :placeholder="i18nT(`Brief Description`)"
                                                        v-model="profileData.OtherInfo"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="12"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Skills`)"
                                                    label-for="skills"
                                                >
                                                    <v-select
                                                        :options="skillsFilter"
                                                        v-model="skills"
                                                        label="text"
                                                        multiple
                                                        push-tags
                                                        taggable
                                                        :clearable="false"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>

                                <!-- CONTACTS -->
                                <b-row class="border-bottom pt-2">
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5>
                                            {{i18nT(`Contacts`)}}
                                        </h5>
                                        <p class="mt-1">{{i18nT(`Next of kin, such as spouse, siblings or other people to contact in case of emergency.`)}}</p>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <div
                                            v-for="(item, index) in contacts"
                                            :key="index"
                                            class="border-bottom pb-1 pt-1"
                                        >
                                            <b-row>
                                                <b-col
                                                    cols="12"
                                                    md="12"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Name`)"
                                                        label-for="salaryNet"
                                                    >
                                                        <b-form-input
                                                            :placeholder="i18nT(`Name`)"
                                                            v-model="item.Name"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                                <b-col
                                                    cols="12"
                                                    md="12"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Relationship`)"
                                                        label-for="Relationship"
                                                    >
                                                        <b-form-input
                                                            :placeholder="i18nT(`Relationship`)"
                                                            v-model="item.Relationship"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                                <b-col
                                                    cols="6"
                                                    md="6"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`E-Mail`)"
                                                        label-for="E-Mail"
                                                    >
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            :name="'contact-email-' + index"
                                                            rules="email"
                                                        >
                                                            <b-form-input
                                                                :placeholder="i18nT(`email@domain.com`)"
                                                                v-model="item.Mail"
                                                                :id="'contact-email-' + index"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col
                                                    cols="6"
                                                    md="6"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Phone`)"
                                                        label-for="Phone"

                                                    >
                                                        <validation-provider

                                                            #default="{ errors }"
                                                            :name="'contact-phone-' + index"
                                                            :rules="{ validMultiPhoneNumber: mazPhones[index] }"
                                                        >
                                                            <MazPhoneNumberInput
                                                                :key="phoneReady+index"
                                                                default-country-code="FR"
                                                                :placeholder="i18nT(`Phone`)"
                                                                v-model="item.Phone"
                                                                @update="updateOnePhoneObj($event, index)"
                                                                :id="'contact-phone-' + index"
                                                                :translations="{
                                                                countrySelectorLabel: i18nT(`Country code`),
                                                                countrySelectorError: i18nT(`Select a country`),
                                                                phoneNumberLabel: i18nT(`Phone number`),
                                                                example: i18nT(`Example:`),
                                                                 }"
                                                            />
                                                            <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <div class="text-right">
                                                <b-button
                                                    variant="outline-danger"
                                                    @click="removeContact(index)"
                                                >
                                                    <feather-icon icon="MinusCircleIcon" />
                                                    {{i18nT(`Remove`)}}
                                                </b-button>
                                            </div>
                                        </div>

                                        <div class="pt-1 mb-2">
                                            <b-button
                                                variant="outline-primary"
                                                @click="addContacts"
                                            >
                                                <feather-icon icon="PlusCircleIcon" />
                                                {{i18nT(`Add more`)}}
                                            </b-button>
                                        </div>
                                    </b-col>
                                </b-row>

                                <!-- KEY DATES -->
                                <b-row class="border-bottom pt-2">
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5>
                                            {{i18nT(`Key dates`)}}
                                        </h5>
                                        <p class="mt-1">{{i18nT(`Key dates such as birthdays, anniversaries and special occasions.`)}}</p>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <div
                                            v-for="(item, index) in keyDates"
                                            :key="index"
                                            class="border-bottom pb-1 pt-1"
                                        >
                                            <b-row>
                                                <b-col
                                                    cols="4"
                                                    md="4"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Date`)"
                                                        label-for="Date"
                                                    >
                                                        <date-picker
                                                            reset-button
                                                            v-model="item.Date"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                                <b-col
                                                    cols="8"
                                                    md="8"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Event description`)"
                                                        label-for="eventDescription"
                                                    >
                                                        <b-form-input
                                                            :placeholder="i18nT(`Event Description`)"
                                                            v-model="item.Event"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <div class="text-right">
                                                <b-button
                                                    variant="outline-danger"
                                                    @click="removeKeyDate(index)"
                                                >
                                                    <feather-icon icon="MinusCircleIcon" />
                                                    {{i18nT(`Remove`)}}
                                                </b-button>
                                            </div>
                                        </div>

                                        <div class="pt-1 mb-2">
                                            <b-button
                                                variant="outline-primary"
                                                @click="addKeyDate"
                                            >
                                                <feather-icon icon="PlusCircleIcon" />
                                                {{i18nT(`Add more`)}}
                                            </b-button>
                                        </div>
                                    </b-col>
                                </b-row>
                                <!-- NEW SOCIAL LINKS -->
                                <b-row class="border-bottom pt-2">
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                    <h5>
                                            {{ i18nT(`Social links`) }}
                                    </h5>
                                    </b-col>

                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                    <div
                                    v-for="(social, index) in addedSocials"
                                        :key="`social`+ index"
                                        class="border-bottom pb-1 pt-1"
                                        >
                                        <b-row
                                        
                                            >
                                            <b-col cols="4" md="4">
                                                <b-form-group
                                                    :label="i18nT(`Social network`)"
                                                    label-for="Social network">
                                                    <v-select
                                                        v-model="social.network"
                                                        :options="availableSocials"
                                                        :clearable="false"
                                                        @input="disableSocial"
                                                        label="text"
                                                        :selectable="selected => selected.disabled !== true"
                                                    ></v-select>
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                    cols="8"
                                                    md="8"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Social link`)"
                                                        label-for="Social link"
                                                    >
                                                        <b-form-input
                                                            :placeholder="i18nT(`Social link`)"
                                                            v-model="social.link"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                        </b-row>
                                        <div class="text-right">
                                                <b-button
                                                    variant="outline-danger"
                                                    @click="removeSocials(index)"
                                                >
                                                    <feather-icon icon="MinusCircleIcon" />
                                                    {{i18nT(`Remove`)}}
                                                </b-button>
                                            </div>
                                    </div>
                                    <div class="pt-1 mb-2">
                                            <b-button
                                                variant="outline-primary"
                                                @click="addSocials"
                                            >
                                                <feather-icon icon="PlusCircleIcon" />
                                                {{i18nT(`Add more`)}}
                                            </b-button>
                                        </div>
                                    </b-col>

                                </b-row>

                                <b-row>
                                    <div style="flex: 1" />
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        variant="primary"
                                        class="mt-0 mt-md-2 ml-1 mr-1"
                                        @click.prevent="validationForm"
                                    >
                                        {{i18nT(`Save`)}}
                                    </b-button>
                                </b-row>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>


        <modal-leaves-edit ref="modalLeavesEdit" :leaveDay="leaveToEdit" @onLeaveUpdated="onLeaveUpdated" />

    </div>

</template>

<script>
import {
    BButton,
    BAlert,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormTextarea,
    BCardHeader,
    BCardBody,
    VBModal,
    BFormRadio,
    //BFormFile,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    BFormSelect,
    VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { dictToSelectArray } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate'
import { required, email, regex, url, integer } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import DatePicker from '@core/components/DatePicker.vue'
import AvatarControl from '@/views/components/avatar/AvatarControl.vue'
import moment from 'moment';
import useAuth from '@/auth/useAuth'
import ModalLeavesEdit from "@/views/hr/LeaveRequest/ModalLeavesEdit.vue";
import VueBootstrapTypeahead from "vue-typeahead-bootstrap"

extend('validPhoneNumber', {
    params: ['phoneNumberData'],
    validate: (value, maz) => {
        if(maz.phoneNumberData){
            return maz.phoneNumberData.isValid ;
        }else {
            return false;
        }
    },
    message: `Invalid phone number`,
});

extend('date_format', {
    validate(value) {
    return {
      required: true,
      valid: moment(value, this.dateFormat).isValid() && value.length === 10 && moment(value).isBetween('1900-01-01', '2999-12-31')
    };
  },
  message: `Invalid date.`,
})

extend('float', {
  validate(value) {
    return {
      required: true,
      valid: /^-?\d+(\.\d+)?$/.test(value),
    };
  },
  message: `Invalid number.`,
});

extend('validMultiPhoneNumber', {
    params: ['phoneNumberData'],
    validate: (value, maz) => {
        if(maz.phoneNumberData) {
            return maz.phoneNumberData.isValid;
        } else {
            return false;
        }
    },
    message: `Invalid phone number`,
});

export default {
    components: {
        ModalLeavesEdit,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BCard,
        BCardHeader,
        BCardBody,
        vSelect,
        BFormTextarea,
        BAlert,
        BBadge,
        BFormCheckbox,
        ValidationProvider,
        ValidationObserver,
        BFormCheckboxGroup,
        BFormRadio,
        //BFormFile,
        BDropdown,
        BDropdownItem,
        BListGroup,
        BListGroupItem,
        BFormSelect,
        //BAvatar,
        DatePicker,
        AvatarControl,
        VueBootstrapTypeahead
    },
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            profileData: {
                Gender: 'male',
                WorkWeek: [],
                FirstName: '',
                LastName: '',
                UserImage: null,
                Phone: '',
                Position: ''
            },
            availableSocials: [
                {value: "Twitter", text: this.i18nT(`Twitter`), disabled: false},
                {value: "Web", text: this.i18nT(`Website`), disabled: false},
                {value: "Behance", text: this.i18nT(`Behance`), disabled: false},
                {value: "Linkedin", text: this.i18nT(`Linkedin`), disabled: false},
                {value: "Dribble", text: this.i18nT(`Dribble`), disabled: false},
                {value: "Github", text: this.i18nT(`Github`), disabled: false},
                {value: "Youtube", text: this.i18nT(`Youtube`), disabled: false},
                {value: "Instagram", text: this.i18nT(`Instagram`), disabled: false},
                {value: "Tiktok", text: this.i18nT(`TikTok`), disabled: false}
            ],
            addedSocials: [{}],
            employmentTypes: [
                {
                    value: "1",
                    text: this.i18nT(`Full time`)
                },
                {
                    value: "2",
                    text: this.i18nT(`Part time`)
                },
            ],
            phoneReady:'no',
            mazphoneObj: {},
            jobSuggestions: [],
            mazPhones: [],
            sendInitialInvite : false,
            file: null,
            UserImage: null,
            UserImageUrl: null,
            contractData: {},
            required,
            email,
            regex,
            url,
            integer,
            countries: [],
            gender: null,
            departments: [],
            jobLevels: [],
            employeeGroupFilters: [],
            employeeGroups: [],
            contractTypes: [],
            skillsFilter: [],
            skills: [],
            scheduleOptions: [
                {
                    value: 1,
                    text: this.i18nT(`Yearly salary`)
                },
                {
                    value: 2,
                    text: this.i18nT(`Monthly salary`)
                },
                {
                    value: 3,
                    text: this.i18nT(`Weekly salary`)
                },
                {
                    value: 4,
                    text: this.i18nT(`Daily salary`)
                },
                {
                    value: 5,
                    text: this.i18nT(`Hourly rate`)
                }
            ],
            workOptions: [
                {
                    value: 1,
                    text: this.i18nT(`Per year`)
                },
                {
                    value: 2,
                    text: this.i18nT(`Per month`)
                },
                {
                    value: 3,
                    text: this.i18nT(`Per week`)
                },
                {
                    value: 4,
                    text: this.i18nT(`Per day`)
                },
            ],
            salary: [{}],
            leaveDays: [],
            leaveToEdit: {},
            contacts: [{}],
            keyDates: [{}],
            selected: '',
            dateFormat: useAuth.getDateFormat() !== 'undefined'
                ? useAuth.getDateFormat()
                : 'DD/MM/YYYY'
        ,
        }
    },
    watch: {
        file: function(val) {
            if (val) {
                this.file = val
                this.fileName = val.name
            } else {
                this.file = null
                this.fileName = ''
            }
        },
        UserImage: function(val) {
            this.UserImageUrl = URL.createObjectURL(val)
        }
    },
    created() {
        let self = this;
        if (router.currentRoute.params.id) {

            this.$http.get(`leaves/leaveDays?employee_id=${router.currentRoute.params.id}`).then(({ data }) => {
                this.leaveDays = data.data.leave_days;
            });
            this.$http
                .get(`employees?id=${router.currentRoute.params.id}`)
                .then(({ data }) => {
                    this.salary = data.data.salary_history
                    this.profileData = data.data.employee
                    this.phoneReady = 'yes';
                    this.contractData = data.data.current_application
                    this.contractData.WorkWeek = this.contractData.WorkWeek && this.contractData.WorkWeek.split(',') || []
                    this.contacts = data.data.contacts
                    this.keyDates = data.data.key_dates
                    data.data.skills.forEach(skill => {
                        self.skills.push({
                            value: skill.Id,
                            text: skill.Label
                        })
                    });

                    
                    this.availableSocials.forEach(social => {
                        if(this.profileData[social.value]) {
                            if(!this.addedSocials[0].network) {
                                this.addedSocials = []
                            }
                            this.addedSocials.push({
                                network: social,
                                link: this.profileData[social.value]
                            })
                            social.disabled = true
                        }
                    })

                    this.employeeGroups = data.data.employee_groups.map(
                        employeeGroup => ({
                            value: employeeGroup.Id,
                            text: employeeGroup.Label
                        })
                    );
                    //contractData.Label

                })
        }

        this.$http.get(`settings/organizationLevels`).then(({ data }) => {
            this.jobLevels = data.data.map(jobLevel => ({
                value: jobLevel.Id,
                text: jobLevel.Label
            }))
        })

        this.$http.get(`system/contractTypes`).then(({ data }) => {
            this.contractTypes = dictToSelectArray(data.data)
        })

        this.$http.get(`system/departments`).then(({ data }) => {
            this.departments = dictToSelectArray(data.data)
        })

        this.$http.get(`employees/employeesPositions`).then(({ data }) => {
            this.jobSuggestions = dictToSelectArray(data.data)
            console.log(this.jobSuggestions)
        })

        this.$http
            .get(`employees/employeeGroups`, {
                page: 0,
                show_per_page: 99999
            })
            .then(({ data }) => {
                this.employeeGroupFilters = data.data.employee_groups.map(
                    employeeGroup => ({
                        value: employeeGroup.group.Id,
                        text: employeeGroup.group.Label
                    })
                )
            })

        this.$http.get(`candidates/skillsFilter`).then(({ data }) => {
            this.skillsFilter = dictToSelectArray(data.data)
        })

        this.$http.get(`system/countries`).then(({ data }) => {
            this.countries = dictToSelectArray(data.data)
        })
    },
    computed: {
        hasRouteId() {
            return router && router.currentRoute.params.id
        },
        currentYear() {
            return moment().format('YYYY')
        },
    },
    methods: {
        returnText(s) {
            return s.text
        },
        onLeaveUpdated(){
            this.$http.get(`leaves/leaveDays?&employee_id=${router.currentRoute.params.id}`).then(({ data }) => {
                this.leaveDays = data.data.leave_days;
            });
        },
        onEditLeave(item){
            this.leaveToEdit = item;
            this.$bvModal.show('modal-edit-leave')
        },
        updatePhoneObj(dataCollected){
            this.mazphoneObj = dataCollected;
        },
        updateOnePhoneObj(dataCollected, index){
            this.mazPhones[index] = dataCollected;
            this.mazPhones = [...this.mazPhones]; // to trigger reactivity
        },
        sendInvite() {
            this.$http
                .post(' employees/sendInvite?user_id='+this.profileData.Id)
                .then(({ data }) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(data),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                }).catch(error => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(error),
                        icon: 'InfoIcon',
                        variant: 'danger'
                    }
                })
            })
        },
        onFilePick: function() {
            this.$refs['filePicker'].$refs.input.click()
        },
        onFileDelete: function() {
            this.file = null
            this.fileName = ''
        },
        validationForm() {
            this.$refs.simpleRules.validateWithInfo().then(({ errors, fields, isValid }) => {
                if (isValid) {
                    const formData = new FormData()
                    if (router.currentRoute.params.id) {
                        formData.append('id', this.contractData.Id)
                    }
                    formData.append('FirstName', this.profileData.FirstName)
                    formData.append('LastName', this.profileData.LastName)
                    formData.append('Gender', this.profileData.Gender)
                    formData.append('Email', this.profileData.Email)
                    formData.append('Phone', this.profileData.Phone)
                    
                    formData.append('BirthDate', this.profileData.BirthDate)
                    formData.append('Nationality', this.profileData.Nationality)
                    formData.append('SocialSecurity', this.profileData.SocialSecurity)

                    formData.append('Address1', this.profileData.Address1)
                    formData.append('Address2', this.profileData.Address2)
                    formData.append('City', this.profileData.City)
                    formData.append('State', this.profileData.State)
                    formData.append('Zip', this.profileData.Zip)
                    formData.append('Country', this.profileData.Country)

                    formData.append('Position', this.profileData.Position)
                    formData.append('OrgJobLevel', this.contractData.OnOrgJobLevel)
                    formData.append('ContractType', this.contractData.OnContractType)
                    formData.append('FullHalfTime', this.contractData.FullHalfTime)
                    formData.append('Department', this.contractData.OnDepartment)
                    formData.append('StartDate', this.contractData.OnStartingFrom)
                    formData.append('EndDate', this.contractData.OnEnding)
                    formData.append('EvaluationPeriodEnd', this.contractData.OnEvaluationPeriod)
                    formData.append('salary_net', this.contractData.OnSalaryNet)
                    formData.append('salary_gross', this.contractData.OnSalaryGross)
                    formData.append('salary_type', this.contractData.OnSalaryType)

                    formData.append('work_hours', this.contractData.WorkHours)
                    formData.append('work_type', this.contractData.WorkType)

                    formData.append(
                        'work_monday',
                        this.contractData.WorkWeek &&
                        this.contractData.WorkWeek.includes('monday')
                            ? 1
                            : 0
                    )
                    formData.append(
                        'work_tuesday',
                        this.contractData.WorkWeek &&
                        this.contractData.WorkWeek.includes('tuesday')
                            ? 1
                            : 0
                    )
                    formData.append(
                        'work_wednesday',
                        this.contractData.WorkWeek &&
                        this.contractData.WorkWeek.includes('wednesday')
                            ? 1
                            : 0
                    )
                    formData.append(
                        'work_thursday',
                        this.contractData.WorkWeek &&
                        this.contractData.WorkWeek.includes('thursday')
                            ? 1
                            : 0
                    )
                    formData.append(
                        'work_friday',
                        this.contractData.WorkWeek &&
                        this.contractData.WorkWeek.includes('friday')
                            ? 1
                            : 0
                    )
                    formData.append(
                        'work_saturday',
                        this.contractData.WorkWeek &&
                        this.contractData.WorkWeek.includes('saturday')
                            ? 1
                            : 0
                    )
                    formData.append(
                        'work_sunday',
                        this.contractData.WorkWeek &&
                        this.contractData.WorkWeek.includes('sunday')
                            ? 1
                            : 0
                    )

                    formData.append('EquityEnd', this.contractData.OnEquityEnd)
                    formData.append('Benefits', this.contractData.OnBenefitsInfo)
                    formData.append('OtherInfo', this.profileData.OtherInfo)

                    for(let i = 0; i < this.addedSocials.length; i++) {
                        if(this.addedSocials[i] && this.addedSocials[i].network) {
                            formData.append(this.addedSocials[i].network.value, this.addedSocials[i].link)
                        }
                    }

                    if (this.UserImage) formData.append('user_image', this.UserImage)

                    formData.append(
                        'employee_groups',
                        this.employeeGroups.map(group => group.value).join(',')
                    )

                    formData.append('employee_skills', this.skills.map(skill => skill.value || skill.text || skill).join(","))
                    formData.append('send_invite', this.sendInitialInvite)

                    for (let i = 0; i < this.contacts.length; i++) {
                        if (this.contacts[i].Id) {
                            formData.append(`contact_id[${i}]`, this.contacts[i].Id)
                        }
                        formData.append(`contact_name[${i}]`, this.contacts[i].Name)
                        formData.append(
                            `contact_relationship[${i}]`,
                            this.contacts[i].Relationship
                        )
                        formData.append(`contact_mail[${i}]`, this.contacts[i].Mail)
                        formData.append(`contact_phone[${i}]`, this.contacts[i].Phone)
                    }

                    for (let i = 0; i < this.keyDates.length; i++) {
                        if (!this.keyDates[i].Date && !this.keyDates[i].Event) {
                            continue
                        }
                        formData.append(`key_date[${i}]`, this.keyDates[i].Date)
                        formData.append(
                            `key_date_description[${i}]`,
                            this.keyDates[i].Event
                        )
                    }

                    for (let i = 0; i < this.salary.length; i++) {
                        formData.append(`salary_history_net[${i}]`, this.salary[i].Net)
                        formData.append(`salary_history_gross[${i}]`, this.salary[i].Gross)
                        formData.append(`salary_history_type[${i}]`, this.salary[i].Type)
                        formData.append(`salary_history_start[${i}]`, this.salary[i].StartDate)
                        formData.append(`salary_history_end[${i}]`, this.salary[i].EndDate)
                    }

                    this.$http
                        .post('employees', formData)
                        .then((data) => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(data),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            //if (!router.currentRoute.params.id)
                            this.$router.push({ name: 'employees' })
                        })
                        .catch(ex => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(ex),
                                    iconF: 'InfoIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                } else {
                    let y = 1000000
                    let prevY = 1000000
                    for (const key in errors) {
                        if (errors[key].length > 0) {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: errors[key][0],
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                            // console.log(fields[key])
                            const el = document.getElementById(fields[key].id)
                            prevY =
                                el.getBoundingClientRect().top + window.pageYOffset - 150
                            if (prevY <= y) y = prevY
                        }
                    }
                    if (y < 1000000) {
                        window.scrollTo({ top: y, behavior: 'smooth' })
                    }
                }
            })
        },
        addSalaryHistory() {
            this.salary.push({})
        },
        removeSalaryHistory(index) {
            this.salary.splice(index, 1)
        },
        addContacts() {
            this.contacts.push({})
        },
        removeContact(index) {
            this.contacts.splice(index, 1)
        },
        addSocials() {
            this.addedSocials.push({})
        },
        disableSocial() {
            this.availableSocials.forEach(soc => soc.disabled = false)

            this.addedSocials.map(social => {
                for (let i = 0; i < this.availableSocials.length; i++) {
                    if(social.network && social.network.value && this.availableSocials[i].value === social.network.value) {
                        this.availableSocials[i].disabled = true
                    }
                } 
            })
        },
        removeSocials(index) {
            this.addedSocials.splice(index, 1)
        },
        addKeyDate() {
            this.keyDates.push({})
        },
        removeKeyDate(index) {
            this.keyDates.splice(index, 1)
        },
        onSave() {
            this.validationForm();
        },
        onDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure you want to delete this entry?`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Confirm`),
                cancelButtonText: this.i18nT(`Cancel`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    console.log('Deleted!!!')
                }
            })
        },
        onSetNewAvatar(newData) {
            this.UserImage = newData.newUserImage;
            this.UserImageUrl = newData.newImageUrl;
            this.profileData = newData.newProfileData;
        },

    }
}
</script>

<style lang="scss">
.min-w-2rem {
    min-width: 2rem !important;
    display: inline-block;
    margin-left: 0.25rem;
}

.draggable-container {
    display: flex;
    justify-content: space-between;
}

.section-title {
    font-size: 11px;
}
.document-name {
    display: inline;
    line-height: 32px;
}
.document-actions-bar {
    float: right;
}
.work-check {
    margin-bottom: 10px;
}
</style>
